import Main from './Loading.vue'
import { createApp, defineComponent, h, ref, Teleport, App, Ref, Component, nextTick } from 'vue'

export class Loading {
  private div?: HTMLDivElement
  private app?: App
  visible: Ref<boolean>
  message: Ref<string>
  duration?: number = 0
  timer?: number

  constructor () {
    this.visible = ref<boolean>(false)
    this.message = ref<string>('')
  }

  install (app: App): void {
    app.config.globalProperties.$loading = {
      show: (options?: {
        message: string
        duration?: number
      } | string) => {
        this.loading(options)
      },
      hide: () => {
        this.hide()
      }
    }
  }

  private create () {
    this.div = document.createElement('div')
    const component: Component = defineComponent(() => {
      return () => h(Teleport, {
        to: 'body'
      }, h(Main, {
        visible: this.visible.value,
        message: this.message.value
      }))
    })
    this.app = createApp(component)
    this.app.mount(this.div)
    document.body.appendChild(this.div)
  }

  loading (options?: {
    message: string
    duration?: number
  } | string): Promise<void> {
    return new Promise<void>(resolve => {
      if (typeof options === 'string') {
        this.message.value = options
        this.duration = 0
      } else if (typeof options === 'undefined') {
        this.duration = 0
        this.message.value = '加载中~'
      } else {
        this.message.value = options.message
        this.duration = options.duration ? options.duration : 0
      }

      if (!this.app) {
        this.create()
      }
      nextTick(() => {
        this.visible.value = true
      })
      if (this.duration > 0) {
        this.clearTimeout()
        this.timer = window.setTimeout(() => {
          this.visible.value = false
          this.clearTimeout()
          resolve()
        }, this.duration)
      }
    })
  }

  hide () {
    this.clearTimeout()
    this.visible.value = false
  }

  clearTimeout () {
    if (this.timer) {
      window.clearTimeout(this.timer)
      this.timer = 0
    }
  }
}

export default new Loading()
