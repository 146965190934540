<template>
  <transition name="v-message-fade">
    <div
      v-show="visible"
      class="v-message">
      <div class="container">
        <div
          v-if="title || $slots.title"
          class="header">
          <slot name="title">{{ title }}</slot>
        </div>
        <div class="body">
          <slot><div class="message">{{ message }}</div></slot>
        </div>
        <div
          v-if="showConfirmButton || showCancelButton"
          class="footer">
          <button
            v-if="showCancelButton"
            class="cancel"
            @click="onCancel">{{ cancelButtonText }}</button>
          <button
            v-if="showConfirmButton"
            class="confirm"
            @click="onConfirm">{{ confirmButtonText }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'v-message',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {
      type: String as PropType<string>,
      default: ''
    },
    message: {
      type: String as PropType<string>,
      default: ''
    },
    showConfirmButton: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    showCancelButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    confirmButtonText: {
      type: String as PropType<string>,
      default: '确认'
    },
    cancelButtonText: {
      type: String as PropType<string>,
      default: '取消'
    }
  },
  setup (props, { emit }) {
    function onCancel (): void {
      emit('update:visible', false)
      emit('cancel')
    }
    function onConfirm (): void {
      emit('update:visible', false)
      emit('confirm')
    }
    return {
      onCancel,
      onConfirm
    }
  }
})
</script>

<style lang="scss" scoped>
.v-message-fade-enter-active {
  animation: message-fade-in 300ms;
}

.v-message-fade-leave-active {
  animation: message-fade-out 300ms;
}

@keyframes message-fade-in {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes message-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

.v-message {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.4);
  .container {
    width: 270px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    .header {
      height: 20px;
      font-size: 18px;
      color: #333;
      line-height: 20px;
      text-align: center;
      padding-top: 20px;
    }
    .body {
      padding: 20px 10px;
      .message {
        font-size: 16px;
        color: #333;
        line-height: 30px;
        white-space: pre-wrap;
        text-align: center;
      }
    }
    .footer {
      display: flex;
      height: 60px;
      border-top: 1px solid #e5e5e5;
      .cancel, .confirm {
        flex: 1;
        height: 60px;
        font-size: 16px;
        background-color: #fff;
        line-height: 60px;
        border: 0;
        outline: 0;
      }
      .cancel {
        color: #999;
        border-right: 1px solid #e5e5e5;
        &:active {
          color: rgba(#999, 0.7);
        }
      }
      .confirm {
        color: #f68733;
        &:active {
          color: rgba(#f68733, 0.7);
        }
      }
    }
  }
}
</style>
