
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'v-loading',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    message: {
      type: String as PropType<string>,
      default: ''
    }
  }
})
