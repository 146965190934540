import Main from './Message.vue'
import { createApp, defineComponent, h, ref, Teleport, App, Ref, Component, nextTick } from 'vue'

export class Message {
  private div?: HTMLDivElement
  private app?: App
  visible: Ref<boolean>
  title: Ref<string>
  message: Ref<string>
  showConfirmButton: Ref<boolean>
  showCancelButton: Ref<boolean>
  confirmButtonText: Ref<string>
  cancelButtonText: Ref<string>
  onUpdateConfirm?(): void
  onUpdateCancel?(): void

  constructor () {
    this.visible = ref<boolean>(false)
    this.title = ref<string>('')
    this.message = ref<string>('')

    this.showConfirmButton = ref<boolean>(true)
    this.showCancelButton = ref<boolean>(false)
    this.confirmButtonText = ref<string>('确认')
    this.cancelButtonText = ref<string>('取消')
  }

  install (app: App): void {
    app.config.globalProperties.$message = this
  }

  private create () {
    this.div = document.createElement('div')
    const component: Component = defineComponent(() => {
      return () => h(Teleport, {
        to: 'body'
      }, h(Main, {
        visible: this.visible.value,
        title: this.title.value,
        message: this.message.value,
        showConfirmButton: this.showConfirmButton.value,
        showCancelButton: this.showCancelButton.value,
        confirmButtonText: this.confirmButtonText.value,
        cancelButtonText: this.cancelButtonText.value,
        'onUpdate:visible': (value: boolean) => {
          this.visible.value = value
        },
        onConfirm: this.onUpdateConfirm,
        onCancel: this.onUpdateCancel
      }))
    })
    this.app = createApp(component)
    this.app.mount(this.div)
    document.body.appendChild(this.div)
  }

  alert (options: {
    message: string
    title?: string
    confirmText?: string
  } | string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      if (!this.app) {
        this.create()
      }
      this.onUpdateConfirm = () => {
        resolve(true)
      }
      if (typeof options === 'string') {
        this.title.value = ''
        this.message.value = options
        this.confirmButtonText.value = '确认'
      } else {
        this.title.value = options.title ? options.title : ''
        this.message.value = options.message
        this.confirmButtonText.value = options.confirmText ? options.confirmText : '确认'
        this.showCancelButton.value = false
      }
      this.showConfirmButton.value = true
      this.showCancelButton.value = false

      if (!this.app) {
        this.create()
      }
      nextTick(() => {
        this.visible.value = true
      })
    })
  }

  confirm (options: {
    message: string
    title?: string
    confirmText?: string
    cancelText?: string
  } | string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.onUpdateConfirm = () => {
        resolve(true)
      }
      this.onUpdateCancel = () => {
        reject(new Error())
      }
      if (typeof options === 'string') {
        this.title.value = ''
        this.message.value = options
        this.confirmButtonText.value = '确认'
        this.cancelButtonText.value = '取消'
      } else {
        this.title.value = options.title ? options.title : ''
        this.message.value = options.message
        this.confirmButtonText.value = options.confirmText ? options.confirmText : '确认'
        this.cancelButtonText.value = options.cancelText ? options.cancelText : '取消'
      }
      this.showConfirmButton.value = true
      this.showCancelButton.value = true

      if (!this.app) {
        this.create()
      }
      nextTick(() => {
        this.visible.value = true
      })
    }).catch(() => false)
  }
}

export default new Message()
