import { Api, PromiseRes } from '.'

export default class NamespaceUser {
  private readonly api: Api
  constructor (api: Api) {
    this.api = api
  }

  /**
   * 发送邀请码
   * phone        手机号
   */
  sendMessage (phone: string): PromiseRes {
    return this.api.get('/user/send-message', {
      params: {
        phone
      }
    })
  }

  /**
   * 注册
   * phone        手机号
   * smsCode      手机验证码
   * inviteCode   邀请码
   * openId       微信唯一标识
   */
  regist (data: {
    phone: string
    smsCode: string
    inviteCode: string
    openId: string
  }): PromiseRes {
    return this.api.post('/user/regist', data)
  }

  /**
   * 查找任务奖励情况
   * openId       微信唯一标识
   */
  findTaskAward (openId: string): PromiseRes<number> {
    return this.api.get<number>('/user/find-task-award', {
      params: {
        openId
      }
    })
  }

  /**
   * 发放任务奖励
   * openId       微信唯一标识
   */
  taskAward (openId: string): PromiseRes {
    return this.api.get('/user/task-award', {
      params: {
        openId
      }
    })
  }
}
