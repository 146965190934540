<template>
  <transition name="v-loading-fade">
    <div
      v-show="visible"
      class="v-loading">
      <div class="container">
        <img
          src="@/assets/images/icons/loading.png"
          class="icon">
        <div
          v-if="message"
          class="message">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'v-loading',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    message: {
      type: String as PropType<string>,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
.v-loading-fade-enter-active {
  animation: loading-fade-in 300ms;
}

.v-loading-fade-leave-active {
  animation: loading-fade-out 300ms;
}

@keyframes loading-fade-in {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes loading-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

@keyframes loading-rotate {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.v-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  .container {
    width: 80px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 20px;
    .icon {
      display: block;
      width: 48px;
      height: 48px;
      margin: 0 auto;
      animation: loading-rotate 1000ms linear 0ms infinite normal;
    }
    .message {
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      margin-top: 10px;
    }
  }
}
</style>
