<template>
  <transition name="v-toast-fade">
    <div
      v-show="visible"
      class="v-toast">
      <div class="container">
        <div class="message">{{ message }}</div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'v-toast',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    message: {
      type: String as PropType<string>,
      default: ''
    }
  }
})
</script>

<style lang="scss" scoped>
.v-toast-fade-enter-active {
  animation: toast-fade-in 300ms;
}

.v-toast-fade-leave-active {
  animation: toast-fade-out 300ms;
}

@keyframes toast-fade-in {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes toast-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 0;
  }
}

.v-toast {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  margin: 0;
  .container {
    min-width: 100px;
    max-width: 300px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px 20px;
    .message {
      font-size: 14px;
      color: #fff;
      text-align: center;
      line-height: 20px;
    }
  }
}
</style>
