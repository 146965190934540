
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'v-message',
  props: {
    visible: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    title: {
      type: String as PropType<string>,
      default: ''
    },
    message: {
      type: String as PropType<string>,
      default: ''
    },
    showConfirmButton: {
      type: Boolean as PropType<boolean>,
      default: true
    },
    showCancelButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    confirmButtonText: {
      type: String as PropType<string>,
      default: '确认'
    },
    cancelButtonText: {
      type: String as PropType<string>,
      default: '取消'
    }
  },
  setup (props, { emit }) {
    function onCancel (): void {
      emit('update:visible', false)
      emit('cancel')
    }
    function onConfirm (): void {
      emit('update:visible', false)
      emit('confirm')
    }
    return {
      onCancel,
      onConfirm
    }
  }
})
