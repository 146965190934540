import { createStore } from 'vuex'

export interface State {
  openId?: string
}

export default createStore<State>({
  state: {
    openId: undefined
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
