import { App } from 'vue'
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'

import NamespaceUser from './user'
import NamespaceGoods from './goods'

export interface Res<D = undefined> {
  code: number
  message: string
  data?: D
}

export type PromiseRes<D = undefined> = Promise<Res<D>>

export class Api {
  private readonly axiosInstance: AxiosInstance
  public readonly user: NamespaceUser
  public readonly goods: NamespaceGoods
  constructor () {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API
    })
    this.axiosInstance.interceptors.request.use(function (
      config: AxiosRequestConfig
    ): AxiosRequestConfig {
      return config
    })
    this.user = new NamespaceUser(this)
    this.goods = new NamespaceGoods(this)
  }

  install (app: App): void {
    app.config.globalProperties.$api = this
  }

  /*
   * 返回值拦截
   */
  interceptResponse<D> (res: AxiosResponse): Res<D> {
    if (res.data) {
      return {
        code: Number(res.data.code),
        message: res.data.message.replace(/^[0-9]+_/g, ''),
        data: res.data.data ? res.data.data : undefined
      }
    } else {
      return {
        code: res.status,
        message: res.statusText
      }
    }
  }

  interceptError<D> (err: AxiosError): Res<D> {
    return {
      code: err.code
        ? Number(err.code)
        : err.response
          ? err.response.status
          : 500,
      message: err.message
        ? err.message
        : err.response
          ? err.response.statusText
          : 'error'
    }
  }

  get<D> (url: string, config?: AxiosRequestConfig): PromiseRes<D> {
    return this.axiosInstance
      .get(url, config)
      .then<Res<D>>(this.interceptResponse)
      .catch<Res<D>>(this.interceptError)
  }

  post<D> (url: string, data?: {}, config?: AxiosRequestConfig): PromiseRes<D> {
    return this.axiosInstance
      .post(url, data, config)
      .then<Res<D>>(this.interceptResponse)
      .catch<Res<D>>(this.interceptError)
  }
}

export default new Api()
