import { Api, PromiseRes } from '.'

export interface Goods {
  channelId: number
  goodsId: number
  goodsSign: string
  imgUrls: Array<string>
  label: string
  price: number
  shopName: string
  title: string
  coupon: number // 优惠券面额
  couponPrice: number // 券后价
  materialUrl?: string // 物料链接
  commission: number // 佣金
  comments?: string // 评论数
  goodCommentsShare?: string // 好评
  salesTip?: string // 销量
}

export interface SearchData {
  totalCount: number
  list: Array<Goods>
}

export interface ShortUrlData {
  shortUrl: string // 商品短链（淘口令）
}

export interface Tpwd {
  longTpwd: string
  tpwd: string
}

export default class NamespaceGoods {
  private readonly api: Api
  constructor (api: Api) {
    this.api = api
  }

  /**
   * 淘宝活动列表
   * materialId  物料id
   * page        分页
   * size        每页条数
   */
  taobaoMaterialOptional (materialId: string, page: number, size: number): PromiseRes<Array<Goods>> {
    return this.api.post<Array<Goods>>('/goods/taobao/material-optional', {
      materialId,
      pageNo: page,
      pageSize: size
    }, {
      baseURL: process.env.VUE_APP_YSH_API
    })
  }

  /**
   * 淘宝活动列表
   * materialId  物料id
   * page        分页
   * size        每页条数
   */
  taobaoActivityGoodsList (materialId: string, page: number, size: number): PromiseRes<Array<Goods>> {
    return this.api.get<Array<Goods>>('/goods/taobao/activity/goods-list', {
      baseURL: process.env.VUE_APP_YSH_API,
      params: {
        activityMaterialId: materialId,
        pageIndex: page,
        pageSize: size
      }
    })
  }

  /**
   * 获取淘宝口令
   * goodsId  商品id
   */
  taobaoGenerateShareGoods (goodsId: number): PromiseRes<Tpwd> {
    return this.api.get<Tpwd>('/goods/taobao/generate/share-goods', {
      baseURL: process.env.VUE_APP_YSH_API,
      params: {
        goodsId
      }
    })
  }

  /**
   * 获取淘宝口令
   * keyWord   关键字
   * channel   渠道
   * sortType  排序类型 0默认 1销量 2返利
   * pageIndex 分页
   * pageSize  每页条数
   * openId    微信唯一标识
   * type      渠道（1：友拾惠；2：惠购小美）
   * unionId   微信开放平台唯一标识
   */
  goodsKeywords (
    keyWord: string,
    channel: number,
    sortType: number,
    pageIndex: number,
    pageSize: number,
    openId: string,
    type: number,
    unionId: string
  ): PromiseRes<SearchData> {
    return this.api.post<SearchData>('/goods/hgxm/goods-keywords', {
      keyWord,
      channel,
      sortType,
      pageIndex,
      pageSize,
      openId,
      type,
      unionId
    }, {
      baseURL: process.env.VUE_APP_YSH_API
    })
  }

  /**
   * 通过商品id转链
   * content  商品id （渠道为拼多多或京东时，参数传入短链）
   * channel  渠道id（1：京东；2：拼多多；3：淘宝）
   * openId   微信唯一标识
   * type     渠道（1：友拾惠；2：惠购小美）
   * unionId  微信开放平台唯一标识
   */
  generateShareForShortUrl (
    content: string,
    channel: number,
    openId: string,
    type: number,
    unionId: string
  ): PromiseRes<ShortUrlData> {
    return this.api.post<ShortUrlData>('/goods/generate/share/for-short-url', {
      content,
      channel,
      openId,
      type,
      unionId
    }, {
      baseURL: process.env.VUE_APP_YSH_API
    })
  }

  /**
   * 获取拼多多授权链接
   * content  商品id （渠道为拼多多或京东时，参数传入短链）
   * openId   微信唯一标识
   * type     渠道（1：友拾惠；2：惠购小美）
   * unionId  微信开放平台唯一标识
   */
  hgxmPddAuth (
    openId: string,
    type: number,
    unionId: string
  ): PromiseRes<ShortUrlData> {
    return this.api.post<ShortUrlData>('/goods/hgxm/pdd/auth', {
      openId,
      type,
      unionId
    }, {
      baseURL: process.env.VUE_APP_YSH_API
    })
  }
}
