import { createApp } from 'vue'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import modal from './components/package/message'
import toast from './components/package/toast'
import loading from './components/package/loading'
import dr from '@jianlc/data-report'

const searchParams: URLSearchParams = new URLSearchParams(window.location.search)
const openId: string | null = searchParams.get('openId')
if (openId) {
  window.localStorage.setItem('token', openId)
}

createApp(App)
  .use(store)
  .use(router)
  .use(api)
  .use(modal)
  .use(toast)
  .use(loading)
  .use(dr, {
    appKey: process.env.VUE_APP_APP_KEY,
    baseUrl: process.env.VUE_APP_BASE_URL
  })
  .mount('#app')
