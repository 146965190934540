<template>
  <div class="home">
    欢迎来到惠购小美~
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component'
export default class Home extends Vue {}
</script>
