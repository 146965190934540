<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: 'DIN';
  src: url('~@/assets/fonts/DIN-Medium.eot') format('embedded-opentype'),
      url('~@/assets/fonts/DIN-Medium.ttf') format('truetype'),
      url('~@/assets/fonts/DIN-Medium.woff') format('woff'),
      url('~@/assets/fonts/DIN-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  user-select: none;
}

html,
body,
button,
input {
  font-family: 'PingFangSC-Regular', 'Helvetica Neue', Helvetica, 'Microsoft Yahei', Tahoma, sans-serif !important;
}

button,
input {
  outline: none;
}

input {
  user-select: text;
}

button {
  -webkit-tap-highlight-color: transparent;
}

ul,
li {
  list-style: none;
}
</style>
