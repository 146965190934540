import { createRouter, createWebHistory, RouteRecordRaw, Router } from 'vue-router'
import Home from '@/views/home/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login/login" */ '../views/login/Login.vue')
  },
  {
    path: '/guide',
    name: 'Guide',
    component: () => import(/* webpackChunkName: "guide/guide" */ '../views/guide/Guide.vue'),
    meta: {
      title: '小美使用攻略'
    }
  },
  {
    path: '/iqiyi',
    name: 'Iqiyi',
    component: () => import(/* webpackChunkName: "iqiyi/iqiyi" */ '../views/iqiyi/Iqiyi.vue'),
    meta: {
      title: '友拾惠爱奇艺会员直充'
    }
  },
  {
    path: '/1111',
    name: '1111',
    component: () => import(/* webpackChunkName: "1111/1111" */ '../views/1111/1111.vue'),
    meta: {
      title: '双十一通关真（gong）经（lue）'
    }
  },
  {
    path: '/taobao',
    name: 'Taobao',
    component: () => import(/* webpackChunkName: "taobao/taobao" */ '../views/taobao/Taobao.vue'),
    meta: {
      title: '双十一员工内购清单'
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search/search" */ '../views/search/Search.vue'),
    meta: {
      title: '搜索商品'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404/404" */ '../views/404/404.vue')
  },
  {
    path: '/*',
    redirect: '/404'
  }
]

const router: Router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTE_BASE),
  routes
})

router.beforeEach(function (to, form, next) {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
