import Main from './Toast.vue'
import { createApp, defineComponent, h, ref, Teleport, App, Ref, Component, nextTick } from 'vue'

export class Toast {
  private div?: HTMLDivElement
  private app?: App
  visible: Ref<boolean>
  message: Ref<string>
  duration?: number
  timer?: number

  constructor () {
    this.visible = ref<boolean>(false)
    this.message = ref<string>('')
  }

  install (app: App): void {
    app.config.globalProperties.$toast = (options: {
      message: string
      duration?: number
    } | string) => {
      this.toast(options)
    }
  }

  private create () {
    this.div = document.createElement('div')
    const component: Component = defineComponent(() => {
      return () => h(Teleport, {
        to: 'body'
      }, h(Main, {
        visible: this.visible.value,
        message: this.message.value
      }))
    })
    this.app = createApp(component)
    this.app.mount(this.div)
    document.body.appendChild(this.div)
  }

  toast (options: {
    message: string
    duration?: number
  } | string): Promise<void> {
    return new Promise<void>(resolve => {
      if (typeof options === 'string') {
        this.message.value = options
        this.duration = 1500
      } else {
        this.message.value = options.message
        this.duration = options.duration ? options.duration : 1500
      }

      if (!this.app) {
        this.create()
      }
      nextTick(() => {
        this.visible.value = true
      })
      this.clearTimeout()
      this.timer = window.setTimeout(() => {
        this.visible.value = false
        this.clearTimeout()
        resolve()
      }, this.duration)
    })
  }

  clearTimeout () {
    if (this.timer) {
      window.clearTimeout(this.timer)
      this.timer = 0
    }
  }
}

export default new Toast()
